.home-container {
  display: flex;
  align-items: center;
  gap: 80px;
  width: 100vw;
}

.home-container .left-container {
  margin-left: 8vw;
  max-width: 31vw;
}

.home-container .left-container .logo {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.home-container .left-container .container-video {
  display: none;
}

.home-container .left-container p {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 200;
  color: #dee9e3;
}

.home-container .left-container .first-text {
  margin-bottom: 25px;
}
.home-container .left-container .second-text {
  margin-bottom: 55px;
}

.home-container .right-container {
  flex: 1;
}

.home-container .container-video {
  position: relative;
}

.home-container .mute-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
}

.home-container .first-border,
.home-container .second-border,
.home-container .third-border {
  border-radius: 50px 0 0 50px;
  position: absolute;
  content: "";
  right: 0;
}

.home-container .first-border {
  background: #e20486;
  top: 5px;
  bottom: -11px;
  left: -15px;
}

.home-container .second-border {
  background: #eeeeee;
  top: 3px;
  bottom: -6px;
  left: -10px;
  z-index: 1;
}

.home-container .third-border {
  background: #e20486;
  top: 2px;
  bottom: 0px;
  left: -5px;
  z-index: 2;
}

.home-container video {
  position: relative;
  z-index: 90;
  height: auto;
  width: 100%;
  border-radius: 50px 0 0 50px;
}

@media only screen and (max-width: 1366px) {
  .home-container .left-container {
    max-width: 36vw;
  }

  .home-container .left-container p {
    font-size: 20px;
  }

  .home-container .left-container .second-text {
    margin-bottom: 25px;
  }

  .home-container .left-container .logo {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .home-container .left-container .container-video {
    display: block !important;
  }

  .home-container .first-border,
  .home-container .second-border,
  .home-container .third-border,
  .home-container .left-container video {
    border-radius: 30px;
  }

  .home-container .right-container {
    display: none;
  }

  .home-container .left-container {
    padding: 8vw;
    max-width: 100%;
    margin-left: 0;
  }

  .home-container .left-container .logo {
    margin-bottom: 20px;
  }

  .home-container .left-container p {
    text-align: center;
    font-size: 20px;
  }

  .home-container .left-container .first-text {
    margin-top: 45px;
  }

  .home-container .first-border {
    top: 7px;
    bottom: -6px;
    left: -9px;
    right: 10px;
  }

  .home-container .second-border {
    top: 7px;
    bottom: -4px;
    left: -6px;
    right: 7px;
  }

  .home-container .third-border {
    top: 3px;
    bottom: -2px;
    left: -3px;
    right: 4px;
  }
}

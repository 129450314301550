.fale-conosco {
  font-family: "Bebas Neue", sans-serif;
  background: #08a158;
  border-radius: 16.5px;
  color: #eeeeee;
  font-size: 44px;
  cursor: pointer;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  outline: 0;
}

.fale-conosco:hover {
  background: linear-gradient(36.71deg, #08a258 37.46%, #61b88e 74.89%);
  box-shadow: 0px 2.75px 2.75px 0px #00000040;
}

.fale-conosco:focus {
  background: linear-gradient(189.41deg, #08a258 2.23%, #033b21 159.43%);
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

@media only screen and (max-width: 1366px) {
  .fale-conosco {
    font-size: 27px !important;
    padding: 18px 0px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .fale-conosco {
    padding: 13px 0px !important;
    border-radius: 10px;
  }

  .fale-conosco svg {
    width: 30px;
    height: 30px;
  }
}

.sobre-nos-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 50px 7vw;
}

.sobre-nos-container .sobre-nos-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 27vh;
  color: #e20486;
  writing-mode: tb;
  transform: rotate(180deg);
  text-wrap: nowrap;
}

.sobre-nos-container .card {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #ffffff;
  border-radius: 70px;
  height: 100%;
  width: 80%;
  padding: 80px 80px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.sobre-nos-container .react-multi-carousel-list {
  position: unset !important;
  height: 100%;
}

.sobre-nos-container .react-multi-carousel-list .react-multi-carousel-track {
  height: 100%;
}

.sobre-nos-container .carousel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 5px;
}

.sobre-nos-container .card .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #201e1f;
  gap: 30px;
  text-align: center;
}

.sobre-nos-container .card .head img {
  width: 82px;
  height: 82px;
}

.sobre-nos-container .card .head span {
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  text-transform: uppercase;
  color: #201e1f;
}

.sobre-nos-container .card .body {
  padding: 20px 40px;
  height: 100%;
  display: flex;
  align-items: center;
}

.sobre-nos-container .card .body span {
  font-family: "Inter", sans-serif;
  font-size: 2.7vh;
  font-weight: 200;
  color: #201e1f;
}

.sobre-nos-container .card .footer {
  width: 60%;
  margin: 0 auto 20px;
}

.sobre-nos-container .sobre-nos-text-mobile {
  display: none;
  font-family: "Bebas Neue", sans-serif;
  font-size: 35px;
  color: #e20486;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1366px) {
  .sobre-nos-container .card {
    width: 75%;
    padding: 20px 40px;
  }

  .sobre-nos-container .sobre-nos-text {
    font-size: 25vh !important;
  }

  .sobre-nos-container .card .head span {
    font-size: 29px !important;
  }

  .sobre-nos-container .card .body span {
    font-size: 18px !important;
  }

  .sobre-nos-container .card .footer .footer-item span {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .sobre-nos-container {
    min-height: 100vh;
    height: auto;
    padding: 30px 7vw;
  }

  .sobre-nos-container .sobre-nos-text {
    display: none;
  }

  .sobre-nos-container .sobre-nos-text-mobile {
    display: block;
  }

  .sobre-nos-container .card {
    width: 100%;
    padding: 20px 10px;
    border-radius: 30px;
  }

  .sobre-nos-container .card .head span {
    font-size: 19px !important;
  }

  .sobre-nos-container .card .head img {
    width: 40px;
    height: 40px;
  }

  .sobre-nos-container .card .body span {
    font-size: 17px !important;
  }

  .sobre-nos-container .card .footer {
    width: 90%;
  }
}

.custom-arrow {
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.custom-left-arrow {
  left: 40px;
}

.custom-right-arrow {
  right: 40px;
}

@media only screen and (max-width: 1366px) {
  .custom-right-arrow {
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .custom-left-arrow {
    left: 20px;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .custom-right-arrow {
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .custom-left-arrow {
    left: 10px;
    width: 30px;
    height: 30px;
  }
}

.contatos-container {
  width: 100%;
  height: 100vh;
  padding: 50px 7vw;
  display: flex;
}

.contatos-container .left-side {
  align-self: flex-end;
}

.contatos-container .title {
  font-family: "Bebas Neue", sans-serif;
  color: #e20486;
  font-weight: 400;
  font-size: 130px;
}

.contatos-container .footer-container {
  margin-top: 20px;
  display: flex;
  gap: 120px;
}

.contatos-container .footer-container .footer-item {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.contatos-container .contato-item {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.contatos-container .contato-item .icon {
  background: #e20486;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contatos-container .contato-item span {
  font-family: "Inter", sans-serif;
  color: #eeeeee;
  font-size: 24px;
  font-weight: 700;
}

.contatos-container .container-img {
  position: relative;
  height: fit-content;
}

.contatos-container .container-img.first {
  margin-left: -60px;
}

.contatos-container .container-img.second {
  margin-top: 110px;
}

.contatos-container .container-img.third {
  width: 50%;
  align-self: center;
}

.contatos-container .img-limousine {
  width: 100%;
  border-radius: 40px;
  position: relative;
  z-index: 5;
}

.contatos-container .first-row {
  display: flex;
  gap: 30px;
  margin-bottom: 3vh;
}

.contatos-container .border-left,
.contatos-container .border-right {
  border-radius: 40px;
  position: absolute;
  content: "";
  right: 0;
  background: #e20486;
  top: 7px;
  bottom: -6px;
  width: 100%;
}

.contatos-container .border-left {
  left: -9px;
}

.contatos-container .border-right {
  left: 9px;
}

.contatos-container .right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contatos-container .mobile {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .contatos-container .footer-container {
    gap: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .contatos-container {
    flex-direction: column;
  }

  .contatos-container .left-side {
    align-self: flex-start;
  }

  .contatos-container .footer-container {
    flex-direction: column;
    margin-top: 0;
  }

  .contatos-container .contato-item span {
    font-size: 18px;
  }

  .contatos-container .contato-item .icon {
    width: 50px;
    height: 50px;
  }

  .contatos-container .title {
    font-size: 100px;
  }

  .contatos-container .desktop {
    display: none;
  }

  .contatos-container .mobile {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
  }

  .contatos-container .mobile .img-limousine,
  .contatos-container .mobile .border-left,
  .contatos-container .mobile .border-right {
    border-radius: 10px;
  }

  .contatos-container .mobile .border-left {
    left: -5px;
  }

  .contatos-container .mobile .border-left,
  .contatos-container .mobile .border-right {
    bottom: -1px;
  }

  .contatos-container .mobile .border-right {
    left: 5px;
  }

  .contatos-container .mobile .right-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #201e1f;
}

section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#home {
  background-color: #201e1f;
}

#eventos {
  background-color: #e20486;
  position: relative;
}

#eventos::before {
  font-family: "Bebas Neue", sans-serif;
  content: "EVENTOS";
  position: absolute;
  font-size: 32vw;
  color: #eb2e9d;
  z-index: 1;
  line-height: 0.9;
}

#avaliacoes {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
}

#avaliacoes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 30, 31, 0.95);
  pointer-events: none;
  z-index: 1;
}

#sobre-nos {
  background-color: #eeeeee;
}

#contatos {
  background-color: #201e1f;
}

.modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal.active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .overlay {
  background: rgba(32, 30, 31, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 999;
  content: "";
  position: absolute;
}

.modal .modal-content {
  width: 80%;
  height: 80%;
  background: #eeeeee;
  border-radius: 20px;
  z-index: 9999;
  transform: scale(0.7);
  opacity: 0;
  animation: openModal 0.5s forwards ease-out;
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
}

@keyframes openModal {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-content .title {
  font-family: "Bebas Neue", sans-serif;
  color: #201e1f;
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  width: fit-content;
}

.modal-content .title::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: #e20486;
  bottom: 5px;
  left: 0;
}

.modal-content .description {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  color: #201e1f;
  font-weight: 200;
}

.modal-content .img-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px 0;
  overflow-y: auto;
}

.modal-content img {
  max-width: 350px;
  border-radius: 20px;
}

.modal-content .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-content .close {
  background: #201e1f;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.modal-content .close:hover {
  background-color: #e20486;
}

.menu {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menu span {
  font-family: "Bebas Neue", sans-serif;
  color: #e3037f;
  font-size: 40px;
  letter-spacing: 0.2em;
  cursor: pointer;
  position: relative;
}

.menu span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 96%;
  height: 2px;
  background-color: #e3037f;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.menu span:hover::after {
  transform: scaleX(1);
}

.saiba-mais {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 200;
  color: #dee9e3;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 50px;
  z-index: 99;
}

.saiba-mais.black {
  color: #201e1f;
}

.icon-menu {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .saiba-mais {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .menu span {
    font-size: 20px;
    color: #201e1f;
  }

  .menu span::after {
    bottom: 3px;
  }

  .saiba-mais {
    font-size: 20px;
  }

  .saiba-mais svg {
    width: 20px;
    height: 20px;
  }

  #eventos {
    align-items: start;
  }

  #eventos::before {
    font-size: 36vh;
    writing-mode: tb;
    transform: rotate(180deg);
  }

  .saiba-mais.black {
    margin-left: auto;
    margin-right: auto;
  }

  section {
    min-height: 100vh;
    height: auto;
  }

  .modal-content .title {
    font-size: 25px;
  }

  .modal-content .description {
    font-size: 14px;
  }

  .modal .modal-content {
    width: 90%;
    padding: 25px 20px;
  }

  .modal .close svg {
    width: 20px;
    height: 20px;
  }

  .modal-content .header {
    align-items: flex-start;
  }

  .icon-menu {
    display: block;
    align-self: flex-start;
    margin-left: 22px;
    margin-top: 20px;
    cursor: pointer;
  }

  .menu.hidden {
    display: none;
  }

  .menu {
    margin-top: 60px;
    position: absolute;
    flex-direction: column;
    background: #e20486;
    align-items: flex-start;
    gap: 15px;
    padding: 20px 50px 20px 30px;
    width: auto;
    left: 25px;
    z-index: 999999;
  }
}

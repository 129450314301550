.avaliacoes-container {
  z-index: 3;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #201e1f;
  border-radius: 70px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  margin: 50px 7vw;
  padding: 50px;
}

.avaliacoes-container .container-title {
  text-align: center;
}

.avaliacoes-container .title {
  color: #e20486;
  font-family: "Bebas Neue", sans-serif;
  font-size: 3vw;
}

.avaliacoes-container .line {
  content: "";
  background: #e20486;
  height: 1px;
  width: 80%;
  margin: 0 auto;
}

.avaliacoes-container .carousel-container {
  padding: 0 150px;
  height: 100%;
}

.avaliacoes-container .carousel-container .title-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.avaliacoes-container .carousel-container .title {
  color: #e20486;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 24px;
}

.avaliacoes-container .carousel-container .stars-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.avaliacoes-container .carousel-container .text {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 200;
  color: #eeeeee;
}

.avaliacoes-container .fale-conosco-container {
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 1366px) {
  .avaliacoes-container .carousel-container .text {
    font-size: 18px;
  }

  .avaliacoes-container {
    margin: 20px 7vw;
    padding: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .avaliacoes-container .carousel-container .text {
    font-size: 14px;
  }

  .avaliacoes-container {
    padding: 30px 0;
    width: 90%;
    height: 85vh;
    margin: auto 0;
    border-radius: 30px;
  }

  .avaliacoes-container .title {
    font-size: 7vw;
  }

  .avaliacoes-container .line {
    display: none;
  }

  .avaliacoes-container .carousel-container {
    padding: 0 60px;
  }

  .avaliacoes-container .title-container {
    flex-direction: column;
  }

  .avaliacoes-container .carousel-container .title-container {
    gap: 4px;
  }

  .avaliacoes-container .carousel-container .title {
    font-size: 18px;
    text-align: center;
  }

  .avaliacoes-container .fale-conosco-container {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .avaliacoes-container .carousel-container {
    padding: 0 50px;
  }

  .avaliacoes-container .carousel-container .title-container {
    margin-bottom: 15px;
  }

  .avaliacoes-container {
    padding: 20px 0;
  }

  .avaliacoes-container .carousel-container .text {
    font-size: 13px;
  }
}

.eventos-container {
  display: flex;
  align-items: end;
  width: 100%;
  height: 100vh;
  padding: 0 7vw 50px;
  z-index: 3;
  position: relative;
}

.eventos-container .react-multi-carousel-list {
  position: unset !important;
}

.eventos-container .carousel-container {
  width: 100%;
}

.eventos-container .carousel-item {
  padding: 10px 20px;
  position: relative;
}

.eventos-container .carousel-item .img-container {
  transition: transform 0.5s ease;
  height: 70vh;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.eventos-container .carousel-item .img {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  transition: transform 0.9s ease;
}

.eventos-container .carousel-item:hover .img {
  transform: scale(1.1);
}

.eventos-container .carousel-item:hover .img-border {
  background-color: #eeeeee;
}

.eventos-container .img-border {
  transition: background-color 0.9s ease;
  border-radius: 20px;
  content: "";
  position: absolute;
  left: 10px;
  top: 20px;
  right: 30px;
  bottom: 0;
  background-color: #201e1f;
  z-index: -1;
}

.eventos-container .carousel-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 20px;
  bottom: 60px;
  z-index: 99;
  width: 450px;
}

.eventos-container .carousel-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  font-weight: 400;
  color: #eeeeee;
  background: #201e1fb2;
  border-radius: 27px 0px 0px 0px;
  padding: 20px 0px 10px 40px;
}

.eventos-container .carousel-subtitle {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 200;
  color: #eeeeee;
  background: #201e1f;
  padding: 10px 0px 10px 40px;
}

.eventos-container .carousel-group.mobile {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .eventos-container .carousel-subtitle {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .eventos-container {
    align-items: center;
    margin: auto 0;
    height: auto;
    padding: 0 7vw;
  }

  .eventos-container .carousel-group.mobile {
    display: flex;
  }

  .eventos-container .img-container .carousel-group {
    display: none;
  }

  .eventos-container .carousel-group {
    position: unset;
    margin: 0 auto;
    width: 95%;
    text-align: center;
    margin-top: -5px;
  }

  .eventos-container .carousel-title {
    font-size: 35px;
    border-radius: 0;
    padding: 25px 0px 10px 0;
  }

  .eventos-container .carousel-subtitle {
    padding-left: 0;
    padding-right: 0;
    font-size: 18px !important;
    border-radius: 0px 0px 15px 15px;
  }

  .eventos-container .carousel-item .img {
    position: relative;
  }

  .eventos-container .img-border {
    left: 15px;
    top: 15px;
    height: 70vh;
  }
}
